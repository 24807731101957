import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swiper from 'swiper';
import { ApiConfig } from '../../api/api-config';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { CategoryDTO, getCategoryList } from '../../api/category-api';

const CategoryArea = () => {
  const [isScrollable, setScrollable] = useState(true)

  const [categories, setCategories] = useState<CategoryDTO[]>([])
  useEffect(() => {
    fetchFavorite()
  }, [])


  const fetchFavorite = async () => {
    getCategoryList()
      .then((res: BaseResponse<CategoryDTO[]>) => {
        if (isSuccess(res)) {
          setCategories(res.data)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

      });

  };


  const navigate = useNavigate();
  return (
    <section className="category-area category-two pt-20">
      <div className="container">
        <div  style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <div className="sub-title text-center mb-20">
            <h3>
              <span className="title-icon"></span> Full topping không hành <span className="title-icon"></span>
            </h3>
          </div>
          <div  className="col-xxl-12 col-md-12 col-12 " style={{display:"flex", justifyContent:"center"}}>
            <div >
              {categories.map((cat, index) => (
                 <Link className="product__item mt-30 flex items-stretch md:items-center ml-30" to={`/category/${cat.id_category}`} key={index}>
                  <div className="category__item category-two__item text-center">
                    <a href={""}className="category__image ">
                      <img className="category__image_hover" src={ApiConfig.API_URL + "/public/category-image" + (index + 1) + ".png"} alt="image" />
                      <div className="category-icon">
                        <img className="category-icon-resize" src={ApiConfig.API_URL + "/public/category-icon" + (index + 1) + ".png"} alt="icon" />
                      </div>
                    </a>
                    <h4 className="mt-30">
                      <a href={""}>{cat.name_category}</a>
                    </h4>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryArea;
