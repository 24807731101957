import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { getOrdersByCategoryWithPagination, Receipt } from '../../api/order-api';
import { getAllParentProducts, ParentProductDTO } from '../../api/parent-product-api';
import ParentProductForm from './create-parent-product';

const ProductForm = () => {
    const getInitialProductState = () => ({
        is_available: 1,
        flavour: '',
        image: '',
        id_parent_product: 0,
    });

    const [product, setProduct] = useState(getInitialProductState());
    const [selectedFile, setSelectedFile] = useState("");
    const [imagePreview, setImagePreview] = useState("");
    const [loading, setLoading] = useState(false);
    const [parentProducts, setParentProducts] = useState<ParentProductDTO[]>([new ParentProductDTO()])
    const [fetchingParentProducts, setFetchingParentProducts] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { label: 'Thêm sản phẩm con' },
        { label: 'Thêm sản phẩm cha' }
    ];

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };
    useEffect(() => {
        fetchFavorite()
    }, [])


    const fetchFavorite = async () => {
        getAllParentProducts()
            .then((res: BaseResponse<ParentProductDTO[]>) => {
                if (isSuccess(res)) {
                    setParentProducts(res.data);
                    setFetchingParentProducts(false);
                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            })

    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        setSelectedFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                setImagePreview(reader.result as string);
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload = async (): Promise<boolean> => {
        if (!selectedFile) return false;

        const formData = new FormData();
        formData.append('file', selectedFile);

        setLoading(true);
        try {
            const response = await axios.post('https://api.thegovape.com/files/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setProduct((prevState) => ({
                ...prevState,
                img_link: response.data.path,
            }));
            product.image = response.data.path
            return true
        } catch (error) {
            console.error('Error uploading image:', error);

            return false
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        await handleFileUpload().then(async (res: boolean) => {
            try {
                if (res) {
                    const response = await axios.post('https://api.thegovape.com/flavours/create', product);
                    console.log('Product created successfully:', response.data);

                    setProduct(getInitialProductState());
                    setSelectedFile("");
                    setImagePreview("");

                    alert('Thêm sản phẩm thành công =))');
                }
                else {
                    setLoading(false);
                    alert('Thêm sản phẩm thất bại :(( ');
                }
            } catch (error) {
                console.error('Error creating product:', error);
            } finally {
                setLoading(false);
            }
        }
        )

    };
    const [orders, setOrders] = useState<Receipt[]>([new Receipt()])
    const currentPage = useRef(1)
    const [total, setTotal] = useState(0)
    const limit = 10
    useEffect(() => {
        fetchOrders()
    }, [])


    const fetchOrders = async () => {
        getOrdersByCategoryWithPagination(currentPage.current, limit)
            .then((res: BaseResponse<{ data: Receipt[], total: number }>) => {
                if (isSuccess(res)) {
                    setOrders(res.data.data)
                    setTotal(res.data.total)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            });

    };

    const totalPages = Math.ceil(total / limit);
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            currentPage.current = page
            fetchOrders()
            scroll()
        }
    };
    const scroll = () => {
        document.querySelector("#top")?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    };
    return (
        <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column" }}>
            <div id='top' style={{ marginTop: "10px", backgroundColor: "white", display: "flex", justifyContent: "center" }} className="tab-buttons">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        style={{ width: "50%", fontWeight: 600 }}

                        className={`px-4 py-1 primary-color tab-button ${activeTab === index ? 'cate-border-chosen' : 'cate-border'}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            {
                activeTab == 0 ?
                    <Box sx={{ paddingLeft: 8, bgcolor: 'white', paddingBottom: 30, paddingTop: 5, paddingRight: 8 }}>
                        <Typography color={'black'} variant="h4" gutterBottom>
                            Luân đẹp try kh ạ?
                        </Typography>
                        {loading && (
                            <Box sx={{ height: 500, display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Flavour"
                                            name="flavour"
                                            value={product.flavour}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="is-available-label">Is Available</InputLabel>
                                            <Select
                                                labelId="is-available-label"
                                                name="is_available"
                                                value={product.is_available}
                                                onChange={handleChange}
                                                label="Is Available"
                                            >
                                                <MenuItem value={0}>No</MenuItem>
                                                <MenuItem value={1}>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="parent-product-label">Parent Product</InputLabel>
                                            <Select
                                                labelId="parent-product-label"
                                                name="id_parent_product"
                                                value={product.id_parent_product}
                                                onChange={handleChange}
                                                label="Parent Product"
                                            >
                                                {fetchingParentProducts ? (
                                                    <MenuItem value="" disabled>Loading...</MenuItem>
                                                ) : (
                                                    parentProducts.map((parentProduct) => (
                                                        <MenuItem key={parentProduct.id_parent_product} value={parentProduct.id_parent_product}>
                                                            {parentProduct.name_product}
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            fullWidth
                                        >
                                            Upload Image
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                        {imagePreview && (
                                            <Box mt={2} textAlign="center">
                                                <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            Create Child Product
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}

                    </Box>
                    :
                    // <div className='mt-10' style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
                    //     <a onClick={() => {
                    //         currentPage.current = 1
                    //         fetchOrders()
                    //     }} style={{ backgroundColor: "#e7e8eb" }} href='#' className='ms-2 px-2 primary-color'>Refresh</a>
                    //     <div>
                    //         {orders.map((product, index) => (
                    //             <div style={{ marginTop: "10px", border: '1px solid #ddd', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                    //                 <p style={{ color: "black" }}><strong>Order #</strong>{product.id_order}</p>
                    //                 <p style={{ color: "black" }}><strong>Khách hàng:</strong> {product.name_customer}</p>
                    //                 <p style={{ color: "black" }}><strong>Địa chỉ:</strong> {product.address_customer}</p>
                    //                 {/* <p><strong>Email:</strong> {email_customer || 'N/A'}</p> */}
                    //                 <p style={{ color: "black" }}><strong>SĐT:</strong> {product.phone}</p>
                    //                 <p style={{ color: "black" }}><strong>Thời gian:</strong> {new Date(parseInt(product.created_date)).toLocaleString()}</p>
                    //                 <p style={{ color: "black" }}><strong>Đã thanh toán:</strong> {product.is_paid ? 'Yes' : 'No'}</p>
                    //                 <div style={{ marginTop: '16px' }}>
                    //                     <div style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: product.order_detail }} />
                    //                 </div>
                    //                 {product.note && (
                    //                     <div style={{ marginTop: '16px' }}>
                    //                         <p style={{ color: "black" }}><strong>ghi chú:</strong> {product.note}</p>
                    //                     </div>
                    //                 )}
                    //             </div>
                    //         ))}
                    //     </div>
                    //     <div className="pagi-wrp mt-65">
                    //         {Array.from({ length: totalPages }, (_, index) => (
                    //             <a
                    //                 href="#0"
                    //                 key={index + 1}
                    //                 style={{ marginRight: 8, color: "black" }}
                    //                 className={`pagi-btn ${currentPage.current === index + 1 ? 'active' : ''}`}
                    //                 onClick={() => { handlePageChange(index + 1) }}
                    //             >
                    //                 {index + 1}
                    //             </a>
                    //         ))}
                    //         <a
                    //             href="#0"
                    //             style={{ color: "black" }}
                    //             className={`fa-regular ms-2 primary-hover fa-angle-right ${currentPage.current < totalPages ? '' : 'disabled'}`}
                    //             onClick={() => { handlePageChange(currentPage.current + 1) }}
                    //         ></a>
                    //     </div>
                    // </div>
                    <ParentProductForm/>
            }
        </div>
    );
};

export default ProductForm;
