
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, CircularProgress, Snackbar, SnackbarOrigin } from "@mui/material";
import React from "react";
import { BaseResponse, isSuccess } from "../../api/base-response";
import { createOrder, Receipt, OrderToPush, ProductInReceipt } from "../../api/order-api";
import { formatNumberWithCommas } from "../../app-utils";
import banner from '../assets/images/banner/pod.webp';
import Footer from "../home/footer";
import Header from "../home/header";
import TopHeader from "../home/top-header";
import { CartManager } from "./cart-manager";
import { ItemCart } from "./item-in-cart";
const Banner = () => {
    const params = useParams();
    const navigate = useNavigate();
    var id = parseInt(params.id + "")
    return (
        <div className="swiper banner-two__slider">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="slide-bg transbox" style={{ background: `url(${banner})`, backgroundRepeat: "no-repeat", backgroundSize: "120%" }}></div>
                    <section className="page-banner bg-image pt-130 pb-130">
                        <div className="container">
                            <h2 className="wow fadeInUp mb-15" data-wow-duration="1.1s" data-wow-delay=".1s">
                                Giỏ hàng
                            </h2>
                            <div className="breadcrumb-list wow fadeInUp" data-wow-duration="1.3s" data-wow-delay=".3s">
                                <Link style={{ fontSize: "18px" }} to={`/`} className="primary-hover">
                                    <i className="fa-solid fa-house me-1"></i> Home <i className="fa-regular text-white fa-angle-right"></i>
                                </Link>
                                <span style={{ fontSize: "18px" }}>Giỏ hàng</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

interface State extends SnackbarOrigin {
    open: boolean;
}
const mapItemCartToProductInReceipt = (
    itemCartList: ItemCart[]
  ): ProductInReceipt[] => {
    return itemCartList.map((item) => ({
      id: 0, // Assuming id is autogenerated or not needed here
      quantity: item.quantity,
      price: item.price.toString(), // Convert price to string if necessary
      name: item.name,
      id_receipt: 0, // Assign the receipt id
    }));
  };
const Products = () => {
    const [cartItems, setCartItems] = useState<ItemCart[]>([]);
    const [message, setMessage] = useState("")
    const cartManager = new CartManager();
    const [isLoading, setLoading] = useState(false);
    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };
   

    const createOrderApi = async () => {
        const order = new Receipt()
        order.delivery_address = formData.address
        order.created_at = Date.now() + ""
        order.email = formData.email
        order.name_customer = formData.name
        order.phone = formData.phone
        // order.note = formData.message
        // order.order_detail = generateGridHtml(cartItems)
        setLoading(false)
        if (cartItems.length == 0) {
            setLoading(false)
            setMessage("Giỏ hàng hiện đang trống, chọn hàng trước khi đặt hàng bạn nha.")
            setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });

        }
        else if (order.name_customer.trim() == "" || order.phone.trim() == "" || order.delivery_address.trim() == "") {
            setLoading(false)
            setMessage("Vui lòng nhập đủ thông tin để shop có thể giao hàng nhanh nhất bạn nha.")
            setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });

        } else if (!((order.phone.trim().match(/\d/g)) && (order.phone.trim().length == 10))) {
            setLoading(false)
            setMessage("Số điện thoại bạn nhập không hợp lệ, bạn vui lòng kiểm tra lại giúp shop nha.")
            setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
        } else if (formData.termsAccepted == false) {
            setLoading(false)
            setMessage("Vui lòng chấp nhận chính sách đổi trả của shop bạn nha.")
            setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
        } else {
            
            createOrder(order, mapItemCartToProductInReceipt(cartItems))
                .then((res: BaseResponse<any>) => {
                    if (isSuccess(res)) {
                        setCartItems([])
                        cartManager.clearCart()
                        setFormData({
                            name: '',
                            email: '',
                            phone: '',
                            address: '',
                            message: '',
                            termsAccepted: false,
                        })
                        setMessage("Đặt hàng thành công. Shop sẽ kiểm tra và gọi xác nhận cho bạn sớm nhất nha ^^")
                        setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
                        setLoading(false)
                    } else {
                        setMessage("Đặt hàng không thành công. Bạn vui lòng đợi 1 lát rồi đặt lại nhé :(")
                        setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setMessage("Đặt hàng không thành công. Bạn vui lòng đợi 1 lát rồi đặt lại nha :(")
                    setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
                    setLoading(false)
                });
        }

    };

    useEffect(() => {
        const fetchCartItems = async () => {
            const items = await cartManager.getItems();
            setCartItems(items);
        };
        scroll()
        fetchCartItems();
    }, []); // Empty dependency array means this effect runs once on component mount

    const scroll = () => {
        document.querySelector("#products")?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // logger.log("#"+categories.getCategoryId(name))
    };
    const handleQuantityChange = (index: number, newQuantity: number) => {
        setCartItems(cartItems.map((product, i) =>
            i === index ? { ...product, quantity: newQuantity } : product
        ));
        cartManager.editItemQuantity(index, newQuantity)
    };

    const handleRemoveProduct = (index: number) => {
        setCartItems(cartItems.filter((_, i) => i !== index));
        cartManager.removeItem(index)
    };

    const calculateTotal = () => {
        return cartItems.reduce((total, product) => total + parseInt(product.price + "") * product.quantity, 0);
    };
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        message: '',
        termsAccepted: false,
    });

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Handle form submission, for example, send the form data to an API
        console.log('Form submitted:', formData);
    };
    return (
        <section className="shopping-cart cart-page  pb-130">

            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-6">

                        <div id="products" className="radius-10 bor sub-bg">
                            <div className="column-labels py-3 px-4 d-flex justify-content-between align-items-center fw-bold text-white text-uppercase">
                                <span className="product-details">Sản phẩm</span>
                                <span className="product-price">Giá</span>
                                <span className="product-quantity">SL</span>
                                <span className="product-line-price">Tổng</span>
                                <span className="product-removal">Xóa</span>
                            </div>
                            {cartItems.map((product, index) => (
                                <div key={index} className="product p-4 bor-top bor-bottom d-flex justify-content-between align-items-center">
                                    <div className="product-details d-flex align-items-center">
                                        <img style={{ borderRadius: 8 }} className="image" src={"https://api.thegovape.com/" + product.image} alt="image" />
                                        <span className="ps-2" style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: `${product.name}` }}></span>
                                    </div>
                                    <div className="product-price">{formatNumberWithCommas(parseInt(product.price + ""))}</div>


                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
                                        <div
                                            className=" primary-color px-2 "
                                            style={{ fontSize: "20px" }}
                                            onClick={() => { if (product.quantity > 1) handleQuantityChange(index, product.quantity - 1) }}
                                        >-</div>
                                        <span className="px-1">{product.quantity}</span>
                                        <div
                                            className=" primary-color px-2 "
                                            style={{ fontSize: "20px" }}
                                            onClick={() => handleQuantityChange(index, product.quantity + 1)}
                                        >+</div>
                                    </div>
                                    <div className="product-line-price">{formatNumberWithCommas((parseInt(product.price + "") * product.quantity))}</div>
                                    <div className="product-removal">
                                        <button className="remove-product" onClick={() => handleRemoveProduct(index)}>
                                            <i className="fa-solid fa-x heading-color"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="totals">
                                <div className="totals-item theme-color float-end mt-20">
                                    <span className="fw-bold text-uppercase py-2">Tổng thanh toán = </span>
                                    <span className="totals-value d-inline py-2 pe-2" id="cart-subtotal">{" " + formatNumberWithCommas(calculateTotal())} VNĐ</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="form-area">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Tên khách hàng ( Bắt buộc )"
                                    value={formData.name}
                                    onChange={handleChange}

                                />
                                <input
                                    type="text"
                                    name="phone"
                                    className="mt-2"
                                    placeholder="SĐT khách hàng ( Bắt buộc )"
                                    value={formData.phone}
                                    onChange={handleChange}

                                />
                                <input
                                    type="text"
                                    name="address"
                                    className="mt-2"
                                    placeholder="Địa chỉ giao hàng ( VD: 89, Pasteur, Quận 1,.. ) ( Bắt buộc )"
                                    value={formData.address}
                                    onChange={handleChange}

                                />

                                <textarea
                                    name="message"
                                    id="message"
                                    placeholder="Ghi chú cho shop ( VD: giao sau 6h chiều; chỉ giao giờ hành chính...)"
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{ color: "white" }}
                                />
                                <div style={{ display: "flex", flexDirection: "row" }} className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="termsAccepted"
                                        checked={formData.termsAccepted}
                                        onChange={handleChange}

                                        style={{ width: 28, height: 28, marginRight: 8, color: "white" }}
                                    />
                                    <label style={{ lineHeight: 1.5 }} htmlFor="terms">Quay lại video khi unbox hàng để shop có thể đổi trả trong trường hợp hàng bị hư hỏng bạn nhé.</label>

                                </div>
                                {isLoading ?
                                    <Box sx={{ height: 50, display: 'flex', justifyContent: 'center', mt: 4 }}>
                                        <CircularProgress color="primary" />
                                    </Box>
                                    :

                                    <button onClick={() => { setLoading(true); createOrderApi(); }} className="mt-40 btn-one" type="submit">
                                        <span>Mua hàng</span>
                                    </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{ width: 800 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClose}
                    message={message}
                    key={vertical + horizontal}
                />
            </Box>
        </section>
    );
};

const Cart = () => (
    <div>
        <TopHeader />
        <Header />
        <main>
            <Banner />

            <Products />

        </main>
        <Footer />

    </div>
);

export default Cart;
