import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";

export class ChildProductDTO {
    id_product: number = 0;
    is_available: number = 1;
    flavour: string = "";
    image: string = "";
    id_parent_product: number = 0;
  }
  
  

export const getProductsByIdParent = async (
    id: number
): Promise<BaseResponse<{ products: ChildProductDTO }>> => {
    try {
        const url = `${CategoryRouter.API_PRODUCTS_by_PARENT_ID(id)}`;
        const response: AxiosResponse<BaseResponse<{ products: ChildProductDTO[]}>> = await axiosClient.get(url, { 
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

