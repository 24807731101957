import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";
import { ChildProductDTO } from "./child-product-api";
export class Receipt {
    id_receipt: number = 0;
    created_at: string = ""; // Use Date type for better date handling
    delivery_address: string = "";
    name_customer: string = "";
    phone: string = "";
    total: number = 0;
    discount_percent: number = 0;
    email: string = "";
  }
  

  export class ProductInReceipt {
    id: number = 0; // Optional: Only if you need to reference it
    quantity: number = 0;
    price: string = ""; // Consider using number if price will always be a number
    name: string = "";
    id_receipt: number = 0 // Reference to the related ChildProduct
  }

export class OrderToPush {
    name_customer: string = "";
    address_customer: string = "";
    email_customer: string = "";
    created_date: string = "";
    is_paid: number = 0;
    order_detail: string = "";
    phone: string = "";
    note: string = ""
}

export const createOrder = async (
  receipt: Partial<Receipt>,
    products: ProductInReceipt[]
  ): Promise<BaseResponse<Receipt>> => {
    try {
      const url = CategoryRouter.API_CREATE_ORDER(); // The endpoint for creating an order
      const payload = {
        receipt, // Spread the order/receipt data
        products, // Attach the list of ProductInReceipt objects
      };
  
      const response: AxiosResponse<BaseResponse<Receipt>> = await axiosClient.post(url, payload, {
        // Additional config options can be added here if needed
      });
  
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
export const getOrdersByCategoryWithPagination = async (
    page: number = 1,
    limit: number = 10
): Promise<BaseResponse<{ products: Receipt[], total: number }>> => {
    try {
        const url = `${CategoryRouter.API_GET_ALL_ORDERS}?page=${page}&limit=${limit}`;
        const response: AxiosResponse<BaseResponse<{ products: Receipt[], total: number }>> = await axiosClient.get(url, {
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
