import React from 'react';
import logo from '../assets/images/logo/logo.svg';
import footer from '../assets/images/footer/footer-bg.jpg';
const Footer = () => {
  return (
    <footer className="footer-area bg-image" style={{ backgroundImage: `url${footer}` }}>
      <div className="container">
        <div className="footer__wrp pt-65 pb-65 bor-top bor-bottom">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="footer__item">
                <h4 className="footer-title">Customer Service</h4>
                <ul>
                  <li><a href="">Help Portal</a></li>
                  <li><a href="">Contact Us</a></li>
                  <li><a href="">Delivery Information</a></li>
                  <li><a href="">Click and Collect</a></li>
                  <li><a href="">Refunds and Returns</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="footer__item">
                <h4 className="footer-title">Get to Know Us</h4>
                <ul>
                  <li><a href="">About Us</a></li>
                  <li><a href="">News & Blog</a></li>
                  <li><a href="">Careers</a></li>
                  <li><a href="">Investors</a></li>
                  <li><a href="">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="footer__item">
                <h4 className="footer-title">Vapes New Collections</h4>
                <ul>
                  <li><a href="">E-Cigarettes</a></li>
                  <li><a href="">Vape Pens</a></li>
                  <li><a href="">Pod Systems</a></li>
                  <li><a href="">Disposable Vapes</a></li>
                  <li><a href="">Nicotine Salt Devices</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="footer__item newsletter">
                <h4 className="footer-title">Get Newsletter</h4>
                <div className="subscribe">
                  <input type="email" placeholder="Your Email" />
                  <button><i className="fa-solid fa-paper-plane"></i></button>
                </div>
                <div className="social-icon mt-40">
                  <a href=""><i className="fa-brands fa-facebook-f"></i></a>
                  <a href=""><i className="fa-brands fa-twitter"></i></a>
                  <a href=""><i className="fa-brands fa-linkedin-in"></i></a>
                  <a href=""><i className="fa-brands fa-youtube"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copy-text pt-50 pb-50">
          <a href="" className="logo d-block">
            <img src={logo} alt="logo" />
          </a>
          <p>&copy; Copyright 2024 <a href="" className="primary-hover">GOVAPE</a> All Rights Reserved</p>
  
        </div>
      </div>
    </footer>
  );
};

export default Footer;
