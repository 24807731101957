import { useEffect, useState } from 'react';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { getRandomProducts, ParentProductDTO } from '../../api/parent-product-api';
import Product from './product';
const ProductArea = () => {

  const [categories, setCategories] = useState<ParentProductDTO[]>([])
  useEffect(() => {
    fetchFavorite()
  }, [])


  const fetchFavorite = async () => {
    getRandomProducts()
      .then((res: BaseResponse<ParentProductDTO[]>) => {
        if (isSuccess(res)) {
          setCategories(res.data)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

      });

  };

  return (
    <section className="product-area bor-bottom pt-50 pb-130 ">
      <div className="container">
        <div className="product__wrp pb-30 mb-65 d-flex flex-wrap align-items-center justify-content-xl-between justify-content-center">
          <div className="section-header d-flex align-items-center wow fadeInUp" data-wow-delay=".1s">
            <span className="title-icon mr-10"></span>
            <h3>Có thể bạn sẽ thích</h3>
          </div>
        </div>
        <div className="tab-content">
          {/* Latest Items */}
          <div id="latest-item" className="tab-pane fade show active">
            <div className="row g-4">
              {categories.map(num => (
                <Product num={num} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductArea;
