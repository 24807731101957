import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { getProductsByName, ParentProductDTO } from '../../api/parent-product-api';
import { formatNumberWithCommas } from '../../app-utils';
import logo from '../assets/images/logo/logo.svg';
import { CartManager } from '../shop/cart-manager';
import { ItemCart } from '../shop/item-in-cart';
const TopHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [productsOPtions, setProductsOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = async (data: any) => {
    const searchQuery = data;

    if (searchQuery.length < 3) {
      setVisible(false)
      setProductsOptions([])
      return;
     } // Don't trigger search for short queries

    setLoading(true);
    getProductsByName(searchQuery)
      .then((res: BaseResponse<ParentProductDTO[]>) => {
        if (isSuccess(res)) {
          const options = res.data.map((country: ParentProductDTO) => ({
            key: country.id_parent_product,
            value: country,
            text: country.name_product,
            image: { avatar: true, src: 'https://api.thegovape.com/'+country.image}
            
          }));

          setVisible(true)
          setProductsOptions(options);
        } else {
          setVisible(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setVisible(false)
      })
      .finally(() => {
        setLoading(false);
      });

    // Make an API call to search countries based on the query


  };


  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    // Handle search logic here
  };
  const [cartItems, setCartItems] = useState<ItemCart[]>([]);
  const cartManager = new CartManager();
  const navigate = useNavigate()
  useEffect(() => {
    const fetchCartItems = async () => {
      const items = await cartManager.getItems();
      setCartItems(items);
    };
    fetchCartItems();
  }, []); // Empty dependency array means this effect runs once on component mount
  // Handle when a product is selected
  const handleProductSelect = (
    e: React.SyntheticEvent<HTMLElement, Event>, 
    data: DropdownProps // Correctly typing with DropdownProps
  ) => {
    if (data.value) {
      var value:any = data.value
      navigate(`/product-detail/${value.id_parent_product}`, { state: { id_category: value.id_category, id_parent_product: value.id_parent_product, price: value.price, name: value.name_product, product_detail: value.product_detail, made_in: value.made_in, technical_detail: value.technical_detail, keywords: value.keywords } }) }
      // Add any additional logic based on the selected product
    }
  return (
    <div className="top__header mt-10 pt-30 ">
      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }} className="top__wrapper" >
          <Link to={`/`} className="main__logo">
            <img src={logo} alt="logo__image" />
          </Link>
          <div className="search__wrp">
            <input
              placeholder='Tìm sản phẩm'
              onChange={v => handleSearchChange(v.target.value)}
            />
            <div className={isVisible?'overlaySearch':'overlaySearchInvisible'}>
              <Dropdown
                className='custom-dropdown'
                //  search
                //  selection
                options={productsOPtions}
                loading={loading}
                onChange={handleProductSelect}
              />
            </div>
            <button style={{marginTop:"220px"}} type="submit">
              <i className="fa-solid fa-search"></i>
            </button>
          </div>
          <div style={{ display: "flex", flexGrow: 1 }} />

          <div className="account__wrap">

            {/* <a style={{ padding: "8px 16px", marginTop: "24px", borderRadius: "8px" }} className="instagram" href='#'>
              <MDBIcon className='me-2' fab icon='instagram' /> TheGoVape
            </a> */}
            {/* <div className="cart d-flex align-items-center">
              <span className="cart__icon">
                <i className="fa-regular fa-cart-shopping"></i>
              </span>
              <a href="#0" className="c__one">
                <span>${cart.total.toFixed(2)}</span>
              </a>
              <span className="one">{cart.itemCount}</span>
            </div>
             */}
            <a href='/cart' className="cart d-flex align-items-center">
              <span className="cart__icon">
                <i className="fa-regular fa-cart-shopping"></i>
              </span>
              <a className="c__one">
                <span style={{ width: "50px", fontSize: "14px" }} className="text-black">
                  {formatNumberWithCommas(cartItems.reduce((total, item) => total + item.total, 0))}
                </span>
              </a>
              <span className="one">
                {cartItems.length}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
