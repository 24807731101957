import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./home/home";
import Shop from "./shop/shop";
import ProductForm from "./admin/create-product";
import ShopDetails from "./shop/shop-detail";
import Cart from "./shop/cart";
import Test from "./admin/test";
import ParentProductForm from "./admin/create-parent-product";

const AppRoute = () => {
  // const [isLoadingScreen, setIsLoadingScreen] = useRecoilState(loadingState);
  // const [isShowModalInfoZalo, setIsShowModalInfoZalo] = useRecoilState(isShowModalInfoZaloState);
  // const [isShowModalExpired, setIsShowModalExpired] = useState(isExpiredLoginState);
  // const userId = useRecoilValue(userIdState);


  return (
    <Router basename="/">
      <Routes>
        <Route path={"/category/:id"} element={<Shop />}></Route>
        <Route index path="/" element={<Home />}></Route>
        <Route index path="/create-product" element={<ProductForm />}></Route>
        <Route index path="/product-detail/:id" element={<ShopDetails />}></Route>
        <Route index path="/cart" element={<Cart />}></Route>
        <Route index path="/test" element={<Test />}></Route>
        <Route index path="/create-parent-product" element={<ParentProductForm />}></Route>
      </Routes>
    </Router >
  );
};

export default AppRoute;
